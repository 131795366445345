<template>
  <div class="not-found--container">
    <h1 v-text="$t(`not_found_view.title`)" />
    <p v-text="$t(`not_found_view.subtitle`)" />
    <v-btn @click="onButtonClick" v-text="$t(`not_found_view.button`)" />
    <img src="/public/img/404.svg?url" alt="404" width="300px" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { LayoutWindowEvent } from '@/tt-app-layout'

type Style = {
  flexDirection: string
  textAlign: string
}

export default Vue.extend({
  name: 'NotFoundView',
  computed: {
    style(): Style {
      const isMobile = this.$vuetify.breakpoint.mdAndDown

      return {
        flexDirection: isMobile ? 'column-reverse' : 'row',
        textAlign: isMobile ? 'center' : 'left',
      }
    },
  },
  methods: {
    onButtonClick() {
      this.$appContext.eventManager.dispatchEvent(LayoutWindowEvent.GO_TO, '/')
    },
  },
})
</script>

<style scoped>
.not-found--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 1em;
}
</style>
